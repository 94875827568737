/* eslint-disable max-len */
import 'src/components/layout/footer/index.scss';
import React, { useEffect, useState } from 'react';
import { useConfig, useTheme } from 'src/hooks';

const Footer = () => {
  const { backgroundColor } = useTheme('footer');
  const { footer: { links = [], copyright = '' } = {}, overrideStyles } = useConfig();

  const isAllied = typeof window !== 'undefined' && sessionStorage.getItem('isAllied') === 'true';
  const domain = typeof window !== 'undefined' && sessionStorage.getItem('domain');

  const defaultLinks = [
    { href: '', label: 'Privacy Policy' },
    { href: '', label: 'Terms and Conditions (T&Cs)' },
    { href: '', label: 'Personal Electronics (T&Cs)' },
    { href: '', label: 'Personal Electronics T&Cs - Arizona' },
    { href: '', label: 'Personal Electronics Terms of Service' },
    { href: '', label: 'Personal Electronics T&Cs - Arizona (Spanish)' }
  ];

  const xoomLinks = [
    { href: '', label: 'Privacy Policy' },
    { href: '', label: 'Terms and Conditions (T&Cs)' }
  ];

  const alliedLinks = [
    { href: '', label: 'Privacy Policy' },
    { href: '', label: 'Terms of Use' },
    { href: '', label: 'Terms and Conditions (EN)' },
    { href: '', label: 'Kitchen and Laundry - Terms and Conditions (EN)' },
    { href: '', label: 'Términos y Condiciones - Cocina y Lavadero (ES)' },
    { href: '', label: 'Personal Electronics - Terms and Conditions (EN)' },
    { href: '', label: 'Términos y Condiciones - Equipos Electronicos Personales (ES)' },
    { href: '', label: 'Personal Electronics - Terms of service (EN)' }
  ];

  const [ sortedLinks, setSortedLinks ] = useState(defaultLinks);
  const [ alliedSortedLinks, setAlliedSortedLinks ] = useState(alliedLinks);
  const [ xoomSortedLinks, setXoomSortedLinks ] = useState(xoomLinks);

  interface Link {
    href: string;
    label: string;
  }

  useEffect(() => {
    const updateLinks = (linksArray: Link[], sortedLinksArray: Link[]) => {
      linksArray.forEach(link => {
        const item = sortedLinksArray.find(item => item.label === link.label);
        if (item) {
          item.href = link.href;
        }
      });
    };

    if (isAllied) {
      const newAlliedSortedLinks = [ ...alliedSortedLinks ];
      updateLinks(links, newAlliedSortedLinks);
      if (JSON.stringify(newAlliedSortedLinks) !== JSON.stringify(alliedSortedLinks)) {
        setAlliedSortedLinks(newAlliedSortedLinks);
      }
    } else if (domain === 'xoomenergy.com') {
      const newXoomdSortedLinks = [ ...xoomSortedLinks ];
      updateLinks(links, newXoomdSortedLinks);
      if (JSON.stringify(newXoomdSortedLinks) !== JSON.stringify(xoomSortedLinks)) {
        setXoomSortedLinks(newXoomdSortedLinks);
      }
    } else {
      const newSortedLinks = [ ...sortedLinks ];
      updateLinks(links, newSortedLinks);
      if (JSON.stringify(newSortedLinks) !== JSON.stringify(sortedLinks)) {
        setSortedLinks(newSortedLinks);
      }
    }
  }, [ links, isAllied ]);

  const renderLinks = (linksArray: Link[]) => (
    linksArray.map((link: Link, index: number) => (
      <span key={link.label}>
        <a href={link.href} target='_blank' rel='noreferrer' className={overrideStyles?.hyperLinkClass} style={overrideStyles?.footer}>{link.label}</a>
        {index < linksArray.length - 1 && <span className='pipe'> |</span>}
      </span>
    ))
  );

  return (
    <footer style={{ backgroundColor }}>
      <div className='footer'>
        {copyright &&
          <span className='footer--copyright' style={overrideStyles?.footer}>
            {copyright.replace(/\d+/g, new Date().getFullYear())}
          </span>
        }
        <div className='footer--links'>
          {isAllied ? renderLinks(alliedSortedLinks) : domain === 'xoomenergy.com' ? renderLinks(xoomSortedLinks) : renderLinks(sortedLinks)}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
